<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { downloadExcel } from "@/state/helpers";
import {
  FETCH_LEDGER,
  DOWNLOAD_VA_EXCEL_LEDGER,
} from "@/state/actions.type";
import * as moment from "moment";
import * as cloneDeep from "lodash.clonedeep";
import Spinner from "vue-simple-spinner";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    Layout,
    PageHeader,
    Spinner,
    DateRangePicker
  },
  computed: {
    title() {
      return "Reserve Ledger";
    },

    items() {
      return [
        {
          text: "Remittance",
          active: true
        },
        {
          text: "Reserve Ledger"
        },
      ];
    },

    rows() {
      return this.$store.state.remittanceReserveLedger.total_elements;
    },

    ledgerDataDataFromStore() {
      return this.$store.state.remittanceReserveLedger.ledgerData;
    },

    isFetchingTransactions() {
      return this.$store.state.remittanceReserveLedger.isFetchingData;
    },
  },
  filters: {
    date(date) {
      return moment(date).format("DD/MM/YYYY");
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    perPage: function (val) {
      this.refreshRecord();
    },
    // eslint-disable-next-line no-unused-vars
    currentPage: function (val) {
      this.refreshRecord();
    },

     pickerDates: {
        handler(){
          this.refreshRecord();
        }, 
        deep: true
    },

    ledgerDataDataFromStore() {
      this.reledgerData = cloneDeep(this.ledgerDataDataFromStore);
    },
  },

  onFiltered(filteredItems) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  },

  methods: {
    ...downloadExcel,
    sortByDate(a,b, key) {
      if(key == 'date') {
        const d1 = new Date(a[key]).getTime();
        const d2 = new Date(b[key]).getTime();
        return d1-d2;
      }
    },

    handleSubmit() {
      var postData = {
          from: this.pickerDates.startDate,
          to: this.pickerDates.endDate,
          page: this.currentPage,
          pagesize: this.perPage,
      };

      this.$store.dispatch(
        `remittanceReserveLedger/${DOWNLOAD_VA_EXCEL_LEDGER}`,
        postData
      );
      this.showmodal = false;
    },

    refreshRecord() {
      var postData = {
          from: this.pickerDates.startDate,
          to: this.pickerDates.endDate,
          page: this.currentPage,
          pagesize: this.perPage,
      };
      this.$store.dispatch(
        `remittanceReserveLedger/${FETCH_LEDGER}`,
        postData
      );
    },
  },

  mounted() {
    // Set the initial number of items
    this.reledgerData = cloneDeep(this.ledgerDataDataFromStore);
  },

  created() {
    this.pickerDates.startDate = new Date();
    this.pickerDates.endDate = new Date();
    this.pickerDates.startDate.setDate(this.pickerDates.startDate.getDate() - 30);
    this.refreshRecord();
    this.reledgerData = cloneDeep(this.ledgerDataDataFromStore);
  },

  data() {
    return {
      showmodal: false,
      // TODO: Fetch channel and method data by APIs
       pickerDates: {
        startDate:"",
        endDate:""
      },
      chaiDate: {
        from: "",
        to: "",
      },
      reledgerData: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500],
      filters: {
        pmt_channel: [],
        pmt_method: [],
        status: [],
      },
      mor_filter: "",
      filterOn: [],
      sortBy: "date",
      sortDesc: true,
      fields: [
        { key: "date", sortable: true, label: "Date" },
        { key: "reserve_entry_type", sortable: true, label: "Reserve Entry Type" },
        { key: "amount", sortable: true, label: "Amount" },
        { key: "record_type", sortable: true, label: "Type (Credit/Debit)"},
        { key: "settlement_reference", sortable: true, label: "Settlement Reference" },
        { key: "actual_balance", sortable: true, label: "Actual Reserve Balance"},
        // { key: "currency", sortable: true, label: "Currency" },
        { key: "description", sortable: true, label: "Description" },
      ],
    };
  },

  beforeDestroy() {
    this.$store.dispatch("notification/clear");
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="">
          <div class="pt-0">
                <div class="d-flex justify-content-between my-4">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;Entries
                    </label>
                  </div>

                  <div class="d-flex">
                    <form style="display: flex" class="ml-1">
                      <date-range-picker class="datepicker-custom" opens="left" :autoApply="true" :showDropdowns="true" :ranges="false" v-model="pickerDates">
                        <template
                          v-slot:input=""
                          style="min-width: 350px;"
                        >{{ pickerDates.startDate | date }} - {{ pickerDates.endDate | date }}</template>
                      </date-range-picker>
                    </form>
                    <span>
                      <button
                        @click="handleSubmit()"
                        class="btn btn-primary btn-sm ml-1"
                      >
                        <i class="mdi mdi-briefcase-download"></i>
                        {{ $t("views.payouts.download") }}
                      </button>
                    </span>
                  </div>
                </div>

                <div class="table-responsive text-center table-custom">
                  <b-table
                    class="table-centered"
                    :items="reledgerData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-compare="sortByDate"
                    :sort-desc.sync="sortDesc"
                    :bordered="true"
                    :filter-included-fields="filterOn"
                    sticky-header="500px"
                    show-empty
                    :empty-text="$t('views.payments.transactions.no-records')"
                    busy.sync="true"
                    hover
                  >
                    <template v-slot:head()="data">
                      <span v-b-tooltip.hover.top :title="data.field.tooltip">
                        {{ data.label }}
                      </span>
                    </template>

                    <template v-slot:cell(amount)="row">
                      <div>
                        <span class="align-middle">{{
                          row.value.toLocaleString("en-US", {
                            style: "currency",
                            currency: row.item.currency,
                          })
                        }}</span>
                      </div>
                    </template>

                    <template v-slot:cell(actual_balance)="row">
                      <div>
                        <span class="align-middle">{{
                          row.value.toLocaleString("en-US", {
                            style: "currency",
                            currency: row.item.currency,
                          })
                        }}</span>
                      </div>
                    </template>

                    <template v-slot:cell(record_type)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'DEBIT',
                          'badge-soft-success': `${row.value}` === 'CREDIT'
                        }"
                      >{{ row.value}}</div>
                    </template>

                  </b-table>
                  <Spinner v-if="isFetchingTransactions === true"></Spinner>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      "
                    >
                      <ul class="pagination pagination-rounded">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="css" scoped>
::v-deep .table > tbody > tr > td {
  white-space: nowrap;
}

::v-deep .table > thead > tr > th {
  white-space: nowrap;
  font-size: medium;
}

::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}

::v-deep #transaction-filter-collapse > .multiselect__tag {
  background-color: #41b883;
}

::v-deep  .datepicker-custom .reportrange-text{
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  font-size: 13px;
  color: #000;
  height: auto;
  padding: 5px 10px;
} 
</style>